import React, { FC, memo, useMemo } from 'react'
import styled from 'styled-components'
import { Text } from '@ucheba/ui/components/Text/desktop'
import { Spacing } from '@ucheba/ui/components/Spacing/desktop'
import { Field, Formik } from 'formik'
import { Form } from '@ucheba/ui/components/Form/desktop'
import { useSelector } from 'react-redux'
import { TextField } from '@ucheba/ui/components/TextField/desktop'
import { EDataKeys } from '@ucheba/utils/types/dataKeys'
import { ErrorText } from '@ucheba/ui/components/ErrorText/touch'
import { Grid, GridCol } from '@ucheba/ui/components/Grid/desktop'
import { Radio } from '@ucheba/ui/components/Radio/desktop'
import { useRouter } from 'next/router'
import { Button } from '@ucheba/ui/components/Button/desktop'
import { Select } from '@ucheba/ui/components/Select/desktop'
import { authPhoneCodeSelectors } from '@ucheba/store/auth/phone/code'
import { profileSelectors } from '@ucheba/store/profile'
import { AGREEMENTS_LINKS } from '@ucheba/utils/constants/core'
import content from '../../../../AppliactionForm/data.json'
import { IEventBrandingUchebaRegistration } from '../types'
import { useEventBrandingUchebaReg } from '../bll'
import PhoneConfirmDialog from '../../../../RequestForm/touch/PhoneConfirmDialog'

const Block = styled.div``

const EventBrandingUchebaRegisration: FC<IEventBrandingUchebaRegistration> = (props) => {
  const {
    institution,
    onSubmitEventReg,
    title,
    subTitle,
    loading,
    onAfterSubmitForm,
    isNeedEmail = true,
    id,
    rubric,
  } = props

  const apiErrors = useSelector(authPhoneCodeSelectors.error)

  const { query } = useRouter()

  const {
    initialValues,
    onSubmit,
    onPhoneConfirmSubmit,
    phone,
    needConfirmPhone,
    isLoading,
    onPhoneConfirmClose,
    isForTutor,
  } = useEventBrandingUchebaReg({
    id,
    onAfterSubmitForm,
    rubricId: rubric?.id,
  })

  const profile = useSelector(profileSelectors.entity)

  const formTitle = useMemo(() => {
    if (title) return title
    const conditionTitle =
      rubric && rubric.id === 5
        ? 'Зарегистрируйтесь на мероприятие и подключите <span style="color: #ff6a00">ТГ-бота</span>'
        : 'Зарегистрируйтесь<br/> на мероприятие и <span style="color: #ff6a00">получите гид абитуриента в подарок</span>'
    return institution ? 'Зарегистрируйтесь на мероприятие' : conditionTitle
  }, [institution, rubric, title])

  const formSubtitle = useMemo(() => {
    if (typeof subTitle === 'string') {
      if (subTitle) return subTitle

      return null
    }

    return institution
      ? 'Мы обязательно напомним вам о мероприятии и пришлем ссылку для подключения'
      : 'А еще мы напомним о мероприятии и пришлем ссылку для подключения'
  }, [institution])

  return (
    <Block id='form'>
      <Text fontSize='h3' lineHeight='h3' fontWeight='extrabold'>
        {formTitle}
      </Text>

      {formSubtitle && (
        <>
          <Spacing spacing='xxsmall' />

          <Text textAlign='left'>{formSubtitle}</Text>
        </>
      )}

      <Spacing spacing='large' />

      <Formik initialValues={initialValues} onSubmit={onSubmitEventReg || onSubmit}>
        {(formikProps): JSX.Element => (
          <Form fillByProfile={!(query.name && query.phone && !!profile)}>
            <Field as={TextField} name={EDataKeys.name} label='Имя' required />

            <Spacing spacing='large' />

            <Field
              as={TextField}
              name={EDataKeys.phone}
              label='Телефон'
              required
              type='tel'
            />

            {apiErrors && apiErrors.code === 400 && (
              <ErrorText>Некорректный телефон</ErrorText>
            )}

            <Spacing spacing='large' />

            {isNeedEmail && (
              <>
                <Field
                  as={TextField}
                  name={EDataKeys.email}
                  label='E-mail'
                  required
                  type='email'
                />

                <Spacing spacing='large' />
              </>
            )}

            {!isForTutor && (
              <>
                <Field
                  values={initialValues.classForm}
                  items={initialValues.classForm}
                  name={EDataKeys.classForm}
                  keyValue='name'
                  label='Класс'
                  required
                  as={Select}
                  onChange={(_, classForm): void =>
                    formikProps.setFieldValue('classForm', classForm)
                  }
                />

                <Spacing spacing='large' />

                <Grid spacing='small'>
                  <GridCol width='auto'>
                    <Field
                      as={Radio}
                      type='radio'
                      name={EDataKeys.isParent}
                      value='false'
                      label={content.application.fields.enrollee}
                    />
                  </GridCol>

                  <GridCol width='auto'>
                    <Field
                      as={Radio}
                      type='radio'
                      name={EDataKeys.isParent}
                      value='true'
                      label={content.application.fields.parent}
                    />
                  </GridCol>
                </Grid>
              </>
            )}

            <Spacing spacing='large' />

            <Button color='orange' block type='submit' progress={!!loading}>
              Отправить
            </Button>

            <Spacing spacing='small' />

            <Text fontSize='xsmall1' lineHeight='xsmall1' textAlign='center' html={false}>
              <span style={{ opacity: '0.5' }}>
                Продолжая, вы соглашаетесь на обработку персональных данных на условиях{' '}
                <a
                  target='_blank'
                  rel='noreferrer'
                  href={`${AGREEMENTS_LINKS.personalData}`}
                  style={{
                    color: 'inherit',
                    textDecoration: 'underline',
                  }}
                >
                  Согласия на обработку персональных данных
                </a>{' '}
                и принимаете условия{' '}
                <a
                  target='_blank'
                  rel='noreferrer'
                  href={`${AGREEMENTS_LINKS.agreementsForMain}`}
                  style={{
                    color: 'inherit',
                    textDecoration: 'underline',
                  }}
                >
                  Пользовательского соглашения.
                </a>
              </span>
            </Text>
          </Form>
        )}
      </Formik>

      {phone && needConfirmPhone ? (
        <PhoneConfirmDialog
          open={needConfirmPhone}
          phone={phone}
          onSubmit={onPhoneConfirmSubmit}
          onClose={onPhoneConfirmClose}
          isLoading={isLoading}
        />
      ) : null}
    </Block>
  )
}

export default memo(EventBrandingUchebaRegisration)
